import {useQuery} from '@tanstack/react-query'

import {api} from '../App.global'
import {useUser} from '../UserProfile'

import {Role} from './Roles.types'
import {
  getAvailableBusinessLines,
  getListChangeFinancePayers,
  getRolesForCustomer,
  getRolesList,
  hasRoleForCustomer,
  hasSalesAgentRole
} from './Roles.utils'

export const RoleKeys = {
  all: () => ['roles'] as const,
  byUserId: (userId?: string) => [...RoleKeys.all(), userId] as const
}

export const useRoles = () => {
  const userId = useUser()
  return useRolesBase(userId)
}

export const useRolesBase = (userId?: string) => {
  return useQuery(
    RoleKeys.byUserId(userId),
    ({queryKey}) => {
      return api
        .get<Role[]>('/roles', {
          params: {userId: queryKey[1]}
        })
        .then((d) => d.data)
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: !!userId
    }
  )
}

export const useRolesByCustomerId = (customerId?: string) => {
  const {data, ...rest} = useRoles()
  return {data: getRolesForCustomer(data ?? [], customerId), ...rest}
}

export const useHasRoleByCustomerId = (customerId?: string, roleType?: string) => {
  const {data, ...rest} = useRoles()
  return {data: hasRoleForCustomer(data, customerId, roleType), ...rest}
}

export const useRolesList = () => {
  const {data, ...rest} = useRoles()
  return {data: getRolesList(data ?? []), ...rest}
}

export const useBusinessLinesByCustomerId = (customerId?: string) => {
  const {data, ...rest} = useRolesByCustomerId(customerId)
  return {data: getAvailableBusinessLines(data), ...rest}
}

export const useIsSaleAgent = () => {
  const {data, ...rest} = useRoles()
  return {data: hasSalesAgentRole(data ?? []), ...rest}
}

export const useListChangeFinancePayerIds = () => {
  const {data, ...rest} = useRoles()
  return {data: getListChangeFinancePayers(data ?? []), ...rest}
}

export const useGetRolesCountWithOrgUnitId = (orgUnitId?: string) => {
  const {data} = useRoles()
  const totalRolesCount = data?.length ?? 0
  const totalRolesWithOrgUnitIdCount = data?.filter((r) => r.dataScope.orgUnitId === orgUnitId).length ?? 0

  return [totalRolesCount, totalRolesWithOrgUnitIdCount];
}
