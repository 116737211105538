import {AxiosResponse} from 'axios'

import {
  PERMISSIONS_FETCH_FAILURE,
  PERMISSIONS_FETCH_REQUEST,
  PERMISSIONS_FETCH_SUCCESS,
  PermissionsActionTypes
} from './Action.types'
import {Permission} from './permissions.types'

export const fetchPermissionsRequest = (): PermissionsActionTypes => ({
  type: PERMISSIONS_FETCH_REQUEST
})

export const fetchPermissionsSuccess = (payload: Permission[]): PermissionsActionTypes => ({
  type: PERMISSIONS_FETCH_SUCCESS,
  payload
})

export const fetchPermissionsFailure = (error: any): PermissionsActionTypes => ({
  type: PERMISSIONS_FETCH_FAILURE,
  error
})

export const fetchPermissions =
  (userId: string) =>
  async (dispatch, getState, {api}) => {
    dispatch(fetchPermissionsRequest())

    try {
      const response: AxiosResponse<Permission[]> = await api.get('/permissions', {
        params: {userId}
      })
      dispatch(fetchPermissionsSuccess(response.data))
      return response.data
    } catch (e) {
      dispatch(fetchPermissionsFailure(e))
      return null
    }
  }
