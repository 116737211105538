/* eslint-disable complexity */
import {catchPromise__deprecated} from '@hconnect/common/catchPromise'
import React, {useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {DEFAULT_CUSTOMER} from '../App.constants'
import {fetchCustomerByCustomerNumber, fetchCustomers} from '../Organisms/Customers'
import {useFeaturesState} from '../Organisms/Features'
import {AppState} from '../Root.store'
import {fetchUserProfile} from '../UserProfile/UserProfile.actions'

import {fetchPermissions} from './permissions.actions'
import {
  getGrantedPermissionTypes,
  getProviderIsFinishedLoading,
  permissionsSelector
} from './permissions.selectors'
import {Permission} from './permissions.types'
import {getDisplayCustomerSimpleLookup} from './permissions.utils'

export * from './permissions.types'

export interface PermissionContext {
  userId: string | null
  grantedPermissionTypes: Set<string>
  getPermissions: (permission: string | string[]) => any
  hasPermission: (permission: string) => boolean
  isFinishedLoading: boolean // is all necessary data available to display the app
  isFetchingPermissions: boolean
  displayCustomerSimpleLookup: boolean
}

const Context = React.createContext<PermissionContext>({
  userId: null,
  grantedPermissionTypes: new Set(),
  isFetchingPermissions: false,
  isFinishedLoading: false,
  displayCustomerSimpleLookup: false,
  getPermissions: () => [],
  hasPermission: () => false
})
Context.displayName = 'Permission'

export const usePermissions = () => useContext(Context)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PermissionProvider = ({children, userId, username}) => {
  const permissions = useSelector((state: AppState) => permissionsSelector(state))
  const grantedPermissionTypes = useSelector((state: AppState) => getGrantedPermissionTypes(state))
  const isFetchingPermissions = useSelector((state: AppState) => state.permissions.isFetching)
  const isFinishedLoading = useSelector((state: AppState) => getProviderIsFinishedLoading(state))

  const {getFeature, isInitialized} = useFeaturesState()
  const customerSearchByNameEnabled = getFeature('CustomersByName')  

  const dispatch = useDispatch()
  const [displayCustomerSimpleLookup, setDisplayCustomerSimpleLookup] = useState(false)

  const loader = async () => {
    const [err, response] = await catchPromise__deprecated(dispatch(fetchPermissions(userId)))
    const permissions: Permission[] = response
    if (err) {
      return
    }

    dispatch(fetchUserProfile(userId))

    const tmp: boolean = getDisplayCustomerSimpleLookup(permissions)
    setDisplayCustomerSimpleLookup(tmp)

    if (tmp) {
      try {
        let customerNumber = null
        const deprecatedCustomerKey = localStorage.getItem(DEFAULT_CUSTOMER)
        const newCustomerKey = localStorage.getItem(`${DEFAULT_CUSTOMER}-${userId}`)
        const customerKey = newCustomerKey || deprecatedCustomerKey
        const customer = customerKey ? JSON.parse(customerKey) : undefined
        const customerName = customer?.customerName
        customerNumber = customer?.customerNumber

        if (customerNumber && !customerSearchByNameEnabled) {
          dispatch(fetchCustomerByCustomerNumber(customerNumber, customerName, userId, false))
          return
        }
        if (customerName && customerNumber) {
          dispatch(fetchCustomerByCustomerNumber(customerNumber, customerName, userId, true))
          return
        }
      } catch {
        localStorage.removeItem(DEFAULT_CUSTOMER)
        localStorage.removeItem(`${DEFAULT_CUSTOMER}-${userId}`)
      }
    } else {
      const useHaulierView = getFeature('UseHaulierView')
      dispatch(fetchCustomers(useHaulierView, userId))
    }
  }
  useEffect(() => {
    isInitialized && void loader()
  }, [isInitialized])

  return (
    <Context.Provider
      value={{
        userId,
        grantedPermissionTypes,
        isFetchingPermissions,
        isFinishedLoading,
        displayCustomerSimpleLookup,
        getPermissions: (permissionTypes: string | string[]) => {
          const perms = Array.isArray(permissionTypes) ? permissionTypes : [permissionTypes]
          return permissions.filter((permission) => perms.includes(permission.permissionType))
        },
        hasPermission: (permissionType) => grantedPermissionTypes.has(permissionType)
      }}
    >
      {children}
    </Context.Provider>
  )
}
