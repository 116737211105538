import {Role} from './Roles.types'

export const getRolesList = (roles: Role[]): string[] =>
  Array.from(roles.reduce((p, v) => p.add(v.roleType), new Set<string>()))

export const getRolesForCustomer = (roles: Role[], customerId?: string) =>
  roles?.filter((r) => r.dataScope.customerIds?.some((c) => c === customerId))

export const hasRoleForCustomer = (roles?: Role[], customerId?: string, roleType?: string) =>
  (roles ?? []).some(
    (r) => r.roleType === roleType && r.dataScope.customerIds?.some((c) => c === customerId)
  )

export const getAvailableBusinessLines = (roles: Role[]) =>
  Array.from(
    roles.reduce((result, role) => {
      if (!role.dataScope.businessLine) return result
      return result.add(role.dataScope.businessLine)
    }, new Set<string>())
  )

export const hasSalesAgentRole = (roles: Role[]) => roles.some((r) => r.roleType === 'SALES_AGENT')

export const getListChangeFinancePayers = (roles: Role[]): string[] =>
  Array.from(
    roles.reduce((agg, curr) => {
      if (
        !['BUSINESS_OWNER', 'CONTROLLER'].includes(curr.roleType) ||
        !curr.dataScope.payerIds ||
        curr.dataScope.payerIds.length < 1
      )
        return agg
      return new Set([...agg, ...curr.dataScope.payerIds])
    }, new Set<string>())
  )
