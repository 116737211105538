import {History} from '@hconnect/uikit'
import {routerMiddleware} from 'connected-react-router'
import {applyMiddleware, createStore} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension/logOnlyInProduction'
import thunk from 'redux-thunk'

import {api} from './App.global'
import appReducer, {resetEnhancer} from './App.reducer'

const middleware = [thunk.withExtraArgument({api}), routerMiddleware(History)]
const rootReducer = appReducer(History)
const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})
export const devStore = (initialState) =>
  createStore(
    resetEnhancer(rootReducer),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

function configureStore(initialState = {}) {
  return devStore(initialState)
}

export type AppState = ReturnType<typeof rootReducer>

export const store = configureStore()

if (process.env.NODE_ENV !== 'production') {
  if ((module as any).hot) {
    ;(module as any).hot.accept('./App.reducer', () => {
      /* eslint-disable-next-line @typescript-eslint/no-var-requires */
      const nextReducer = require('./App.reducer').default
      store.replaceReducer(nextReducer(History))
    })
  }
}
