import {Permission} from './permissions.types'

export const PERMISSIONS_FETCH_REQUEST = 'PERMISSIONS/FETCH_REQUEST'
export const PERMISSIONS_FETCH_SUCCESS = 'PERMISSIONS/FETCH_SUCCESS'
export const PERMISSIONS_FETCH_FAILURE = 'PERMISSIONS/FETCH_FAILURE'

interface FetchPermissionsRequestAction {
  type: typeof PERMISSIONS_FETCH_REQUEST
}

interface FetchPermissionsSuccessAction {
  type: typeof PERMISSIONS_FETCH_SUCCESS
  payload: Permission[]
}

interface FetchPermissionsFailureAction {
  type: typeof PERMISSIONS_FETCH_FAILURE
  error: any
}

export type PermissionsActionTypes =
  | FetchPermissionsFailureAction
  | FetchPermissionsRequestAction
  | FetchPermissionsSuccessAction

export interface PermissionsStateType {
  isFetching: boolean
  items: Permission[]
  error: any
}
