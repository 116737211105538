import {createSelector} from 'reselect'

import {AppState} from '../Root.store'

import {PermissionTypes} from './permissions.types'
import {getDisplayCustomerSimpleLookup} from './permissions.utils'

export const permissionsSelector = (state: AppState) => state.permissions.items

export const getProviderIsFinishedLoadingUserProfile = (state: AppState): boolean =>
  !!state.userProfile.userProfile

export const getProviderIsFinishedLoading = (state: AppState): boolean => {
  if (state.userProfile.userProfile && state.permissions.items.length > 0) {
    if (state.userProfile.userProfile.isInternal) {
      return true
    }
    return state.customers.customers.length > 0
  }
  return false
}

// returns a set of permissionTypes, e.g. Set(['VIEW_INVOICES'])
export const getGrantedPermissionTypes = createSelector(
  permissionsSelector,
  (items): Set<string> => {
    const permissionTypes: Set<string> = new Set()

    // eslint-disable-next-line no-unused-vars
    for (const item of items) {
      permissionTypes.add(item.permissionType)
    }
    return permissionTypes
  }
)

export const getDisplayCustomerSimpleLookupSelector = createSelector(
  permissionsSelector,
  (items): boolean => getDisplayCustomerSimpleLookup(items)
)

export const getBussinessLineDatascopes = (permissionType: PermissionTypes) =>
  createSelector(permissionsSelector, (items): string[] =>
    items.reduce<string[]>((agg, curr) => {
      if (curr.permissionType === permissionType) {
        const {dataScope} = curr
        const {businessLineId} = JSON.parse(dataScope)
        !agg.includes(businessLineId) && agg.push(businessLineId)
      }
      return agg
    }, [])
  )
