// see https://api.hce.heidelbergcement.com/API/foundation#permissions-list-permissions-get
export enum PermissionTypes {
  VIEW_ORDERS_DELIVERIES = 'VIEW_ORDERS_DELIVERIES',
  CHANGE_ORDERS = 'CHANGE_ORDERS',
  VIEW_FINANCE = 'VIEW_FINANCE',
  VIEW_INVOICES = 'VIEW_INVOICES',
  CHANGE_INVOICES = 'CHANGE_INVOICES',
  VIEW_QOUTES = 'VIEW_QOUTES',
  CHANGE_QUOTES = 'CHANGE_QUOTES',
  VIEW_TESTS = 'VIEW_TESTS',
  CHANGE_TESTS = 'CHANGE_TESTS',
  VIEW_ALL_DATA = 'VIEW_ALL_DATA',
  CHANGE_ALL_DATA = 'CHANGE_ALL_DATA',
  VIEW_USERS = 'VIEW_USERS',
  INVITE_USERS = 'INVITE_USERS',
  DELETE_USERS = 'DELETE_USERS',
  CHANGE_USERS = 'CHANGE_USERS',
  GRANT_USER_MANAGEMENT_RIGHTS = 'GRANT_USER_MANAGEMENT_RIGHTS',
  GRANT_SUPERADMIN_RIGHTS = 'GRANT_SUPERADMIN_RIGHTS',
  VIEW_CUSTOMERS = 'VIEW_CUSTOMERS',
  VIEW_PLANTS = 'VIEW_PLANTS',
  VIEW_MATERIALCERTIFICATES = 'VIEW_MATERIALCERTIFICATES',
  VIEW_MATERIALTESTS = 'VIEW_MATERIALTESTS',
  ACCESS_PRODUCT_HUB = 'ACCESS_PRODUCT_HUB',
  VIEW_CREDIT = 'VIEW_CREDIT',
  CHANGE_MATERIALCERTIFICATES_SNIPPETS = 'CHANGE_MATERIALCERTIFICATES_SNIPPETS',
  CHANGE_FINANCE = 'CHANGE_FINANCE',
  VIEW_PAYERS = 'VIEW_PAYERS'
}

export interface Permission {
  userId: string
  connector: string
  permissionType: keyof typeof PermissionTypes
  dataScope: string
  expiryDate: string | null
}

export interface DataScope {
  countryId?: string
  orgUnitId?: string
  businessLineId?: string
  marketId?: string
  customerId?: string
  projectId?: string
  siteId?: string
  orderId?: string
}
