export const getDisplayCustomerSimpleLookup = (permissionsList): boolean => {
  if (!Array.isArray(permissionsList)) return false
  for (const permission of permissionsList) {
    const dataScope = JSON.parse(permission.dataScope)
    if (
      permission.permissionType === 'VIEW_CUSTOMERS' &&
      !(dataScope.customerId || dataScope.payerId)
    ) {
      return true
    }
  }
  return false
}
