import {
  PERMISSIONS_FETCH_FAILURE,
  PERMISSIONS_FETCH_REQUEST,
  PERMISSIONS_FETCH_SUCCESS,
  PermissionsActionTypes,
  PermissionsStateType
} from './Action.types'

export const initialState: PermissionsStateType = {
  isFetching: false,
  items: [],
  error: null
}

export const permissionsReducer = (state = initialState, action: PermissionsActionTypes) => {
  switch (action.type) {
    case PERMISSIONS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        items: action.payload
      }

    case PERMISSIONS_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    case PERMISSIONS_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      }

    default:
      return state
  }
}
